import {
  Box,
  Link,
  InputLabel,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  CustomButton,
  CustomDropdown,
  CustomLoader,
} from "../../Components/Common";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { getCredits, getPurchaseDetails } from "../../Services/APIs/checkout";
import { extractFirstName, extractLastName, generateEventId, generateItemData, getLocalData, isMetaLead, pushToDataLayer } from "../../Utils";
import { bookAppointment, getEventData, updateAppointment } from "../../Services/APIs";
import ItemDetail from "../Shop/ItemDetail";
import { getCurrentTimeZone} from "../../Utils";
import { getStoreDetails } from "../../Services/APIs/metaDataAnalytics";
const downArrow = require("../../assets/images/dropdownArrow.svg").default;

export default function Checkout({
  clientDetail,
  bookingType,
  selected,
  handleBack,
  paramsFilter,
  setPage,
  selectedOption,
  setSelectedOption,
  isCreditBuy,
  creditOptions,
  setCreditOptions,
  isWaitlist
}) {
  console.log(' selected->', selected);
  console.log(' selectedOption->', selectedOption);
  // buyNow was available in function call like this - {handleNext, buyNow}
  const locationId = getLocalData("locationId");
  const userData = getLocalData("user_detail");
  const franchiseeId = getLocalData("franchiseeId");
  let clientEmail = JSON.parse(userData);
  const [loader, setLoader] = useState(false);
  const [creditUsed, setCreditUsed] = useState(false);
  const [creditRemaining, setCreditRemaining] = useState(0);
  const [creditSelectedUsed, setCreditSelectedUsed] = useState(0);
  const [open, setOpen] = useState(false);

  const {taxAmount, coupon} = selectedOption?.creditsValue?.value?.purchaseItems || {};
  const locallyStoredUserData = getLocalData("userDataForDataLayer");
  const userDetails = locallyStoredUserData ? JSON.parse(locallyStoredUserData) : null;
  const dataLayerContent = {
    event: "trackOnlineConversion",
    event_name: "Purchase",
    event_time: Math.floor(new Date().getTime() / 1000),
    action_source: "online",
    event_id: generateEventId(),
    transactionDetails: {
      tax: taxAmount || "",
      coupon: coupon || "",
      affiliation: getLocalData("franchiseeName"),
      currency: "USD",
    },
    user_data: userDetails,
  }
  const [dataLayer, setDataLayer] = useState(dataLayerContent);
  console.log(' Checkout ~ dataLayer->', dataLayer);

  useEffect(() => {
    fetchEventDetails();
    fetchStoreDetails();
  }, []);

  // sets the store details
  const fetchStoreDetails = async () => {
    try {
      const response = await getStoreDetails();
      if (response?.statusCode === 200) {
        const storeData = response?.data?.Item;
        setDataLayer((prev) => {
          const updatedStoreDetails = {
            store_id: storeData?.locationId,
            store_name: storeData?.locationName,
            store_location: `${storeData?.addressLine1} , ${storeData?.addressLine2}, ${storeData?.state} - ${storeData?.postalCode}`,
            store_city: storeData?.city,
          }
          return {
            ...prev,
            store_data: { ...prev?.store_data, ...updatedStoreDetails }
          }
        });
      }
    } catch (error) {
      console.error(error.message);
      return error;
    }
  };

  // sets the tracking meta ad details
  const fetchEventDetails = async () => {
    try {
      const response = await getEventData();
      console.log(' fetchEventDetails ~ response->', response);
      if (response?.statusCode === 200) {
        const data = response?.data?.Items.length > 0 ? response?.data?.Items[0] : null;
        const { ad_store_id = '', ad_store_city, ad_store_location, ad_store_name, ...adData } = data?.trackingData;
        let dataLayerUpdatedStoreDetailsData = {};
        dataLayerUpdatedStoreDetailsData.ad_store_name = ad_store_name;
        dataLayerUpdatedStoreDetailsData.ad_store_location = ad_store_location;
        dataLayerUpdatedStoreDetailsData.ad_store_city = ad_store_city;
        dataLayerUpdatedStoreDetailsData.ad_store_id = ad_store_id;
        setDataLayer((prev) => {
          return {
            ...prev,
            store_data: { ...prev?.store_data, ...dataLayerUpdatedStoreDetailsData },
            trackingData: { ...prev?.trackingData, ...adData }
          }
        })
      }
    } catch (error) {
      console.error(error.message);
      return error;
    }
  };
  
  useEffect(() => {
    let { repeat, fromDate, toDate } = selected[0]
    repeat = 'noRepeat'
    fromDate = fromDate.replaceAll('/', '-')
    toDate = toDate.replaceAll('/', '-')
    // AD: 16-11-23: Added the length check to avoid the error when 'selected' is not available
    let serviceId = (selected?.length > 0 && selected[0]?.serviceId) || selectedOption?.serviceName?.value?.sortKey;
    let categoryId = (selected?.length > 0 && selected[0]?.categoryId) || selectedOption?.categoryName?.value?.sortKey;
    let clientId = clientDetail?.sortKey;
    let petId = selectedOption?.dog?.value?.sortKey;
    let requestObj = {
      serviceId, categoryId, clientId, locationId, petId, repeat, fromDate, toDate
    }

    getCredits(requestObj).then(
      (response) => {
        if (response) {
          let creditObjList = response?.data?.Items || response?.data || [];
          const credit = creditObjList?.map((item) => ({
            label: item.itemName, // Assuming 'className' is a property in your 'item' object
            value: item,
          }));
          const validCredits = credit?.filter((item) => item?.value?.remainingSession !== 0)
          setCreditOptions(validCredits);
        }
      }
    );
  }, []);

  const getPurchaseDetail = async () => {
    const purchaseDetails = selectedOption?.creditsValue?.value;
    const payload = {
      orderId: [purchaseDetails?.orderId],
      purchaseId: [purchaseDetails?.sortKey],
      locationId: purchaseDetails?.locationId,
      clientId: purchaseDetails?.partitionKey,
    }
    const response = await getPurchaseDetails(payload);
    return response.data;
  }

  const handleAppointment = () => {
    if (selected) {
      if (selected[0].scheduleType === "enrollment") {
        updateAppointmentDetails();
      } else {
        setAppointment();
      }
    }
  };

  useEffect(() => {
    setSelectedOption({ ...selectedOption, creditsValue: {} });
  }, []);

  const setAppointment = () => {
    setLoader(true);
    let room = {
      id: selectedOption?.room?.roomId,
      name: selectedOption?.room?.roomName,
      roomLimit: 20,
    };
    let paretlastName = clientDetail?.lastName;
    let childLastName = selectedOption.dog.value.lastName;
    const payLoad = {
      scheduleType: "appointment",
      clientDetails: [
        {
          payLater: false,
          creditAvailable: 1,
          checkIn: "00:00",
          clientParentId: clientDetail?.sortKey,
          clientChildId: selectedOption.dog.value.sortKey,
          clientParentName: clientDetail?.firstName,
          // clientParentLastName: clientDetail?.lastName|| " ",
          clientChildName: selectedOption.dog.value.firstName,
          // clientChildLastName: selectedOption.dog.value.lastName|| " ",
          clientChildProfile: selectedOption.dog.value.profileImage,
          clientChildBirthDate: selectedOption.dog.value.birthDate,
          clientEmailId: clientEmail.email,
          purchaseId: [selectedOption.creditsValue.value.sortKey],
          bookedBy: clientDetail.firstName
        },
      ],
      categoryName: selectedOption.categoryName.value.name,
      categoryId: selectedOption.categoryName.value.categoryId,
      serviceName: selectedOption.serviceName.value.name,
      serviceId: selectedOption.serviceName.value.sortKey,
      repeat: "noRepeat",
      occurrence: {
        freq: "noRepeat",
        dtstart: `${selectedOption.fromDate}, ${selectedOption.startTime}`,
        until: `${selectedOption.fromDate}, ${selectedOption.endTime}`,
      },
      room: room,
      description: selectedOption.notes,
      createdBy: clientDetail.firstName,
      trainerId: selectedOption.trainerId,
      trainerName: extractFirstName(selectedOption?.trainerName),
      trainerLastName: extractLastName(selectedOption?.trainerName) || " ",
      trainerProfileImage: selectedOption.trainerProfileImage,
      trainerAvailability: selectedOption.trainerAvailability,
      locationId: locationId,
      franchiseeId: franchiseeId,
      timeZone: getCurrentTimeZone()
     
    };
    if(paretlastName){
      payLoad.clientDetails[0].clientParentLastName = paretlastName
    }
    if(childLastName){
      payLoad.clientDetails[0].clientChildLastName = childLastName
    }
    bookAppointment(payLoad).then(async (response) => {
      console.log(' bookAppointment ~ response->', response);
      if (response.statusCode === 200) {
        if (response?.data?.statusCode === 400) {
          setLoader(false);
          setOpen(true);
        } else {
          const appointmentId = response?.data?.AppointmentId;
           // push datalayer here
            if (isMetaLead(dataLayer)) {
              const purchaseDetails = await getPurchaseDetail();
              const { itemType, itemName, itemCategoryName } = selectedOption?.creditsValue?.value?.purchaseItems || {};
              const updatedTransactionDetails = {...dataLayer?.transactionDetails, transaction_id: appointmentId,  items: [{...generateItemData(itemType, itemCategoryName, appointmentId, itemName, "booking"), quantity: 1, price: purchaseDetails[0]?.pricePerSession } ], value: purchaseDetails[0]?.pricePerSession, content_ids: [purchaseDetails[0]?.itemId]};
              console.log('Appointment ~ dataLayer->', { ...dataLayer, transactionDetails: updatedTransactionDetails });
              pushToDataLayer({ ...dataLayer, transactionDetails: updatedTransactionDetails });
            }
          setLoader(false);
          setPage(4);
        }
      } else {
        setLoader(false);
      }
    });
  };

  const updateAppointmentDetails = () => {
    setLoader(true);
    const appointmentId = selected[0].sortKey;
    const payload = {
      clientDetails: {
        clientParentId: clientDetail.sortKey,
        serviceId: selected[0]?.serviceId,
        serviceName: selected[0]?.serviceName,
        categoryId: selected[0]?.categoryId,
        categoryName: selected[0]?.categoryName,
        clientParentName: clientDetail?.firstName,
        clientParentLastName: clientDetail?.lastName || " ",
        clientChildId: selectedOption.dog.value.sortKey,
        clientChildName: selectedOption.dog.value.firstName,
        clientChildLastName: selectedOption.dog.value.lastName || " ",
        clientChildProfile: selectedOption.dog.value.profileImage,
        clientChildBirthDate: selectedOption?.dog?.value?.birthDate,
        bookedBy: clientDetail.firstName,
        payLater: false,
        creditAvailable: 2,
        clientEmailId: clientDetail.email,
        waitingList: isWaitlist,
        purchaseId: [selectedOption.creditsValue.value.sortKey],
      },
      fromDate: selected[0].fromDate,
      locationId: locationId,
      updatedBy: selected[0].createdBy,
      type: "occurrence",
    };
    updateAppointment(payload, appointmentId).then(async (response) => {
      if (response.statusCode === 200) {
        console.log('Enrollment ~ response-> enrollment', response);
        const appointmentId = response?.data["Updated Details"]?.appointmentId;
         // push datalayer here
              if (isMetaLead(dataLayer)) {
              const purchaseDetails = await getPurchaseDetail();
                const { itemType, itemName, itemCategoryName } = selectedOption?.creditsValue?.value?.purchaseItems || {};
                const updatedTransactionDetails = {...dataLayer?.transactionDetails, transaction_id: appointmentId, items: [{...generateItemData(itemType, itemCategoryName, appointmentId, itemName, "booking"), quantity: 1, price: purchaseDetails[0]?.pricePerSession} ], value:purchaseDetails[0]?.pricePerSession, content_ids: [purchaseDetails[0]?.itemId]};
                console.log('Enrollment ~ dataLayer->', { ...dataLayer, transactionDetails: updatedTransactionDetails });
                pushToDataLayer({ ...dataLayer, transactionDetails: updatedTransactionDetails });
              }
        setLoader(false);
        window?.location?.pathname === "/explore/explore-details" ? setPage(6) : setPage(7);
      } else {
        setLoader(false);
      }
    });
  };

  const handleDropdownChange = (name, value) => {
    setCreditUsed(true);
    if (
      value?.value?.remainingSession > 0 ||
      value?.value?.purchaseItems?.numberOfSessions > 0
    ) {
      setCreditRemaining(
        value?.value?.remainingSession
      );
      setCreditSelectedUsed(
        value?.value?.purchasedSession - value?.value?.remainingSession < 0
          ? 0
          : value?.value?.purchasedSession - value?.value?.remainingSession || 0
      );
    } else {
      setCreditRemaining(0);
      setCreditSelectedUsed(0);
    }
    setSelectedOption({
      ...selectedOption,
      creditsValue: value, // Update creditsValue with the provided value
    });
  };

  const handlePageNavigation = () => {
    if (window.location.pathname === "/explore/explore-details") {
      setPage(2)
    } else {
      if (bookingType === "Appointment") {
        setPage(3);
      } else if (bookingType === "Enrollment") {
        setPage(3);
      } else {
        setPage(0);
      }
    }
  };

  function setAddToCartPage() {
    setPage(5);
  }

  function errorMessege() {
    let message = "";
    if (creditOptions?.length === 0) {
      message = "Credits Not Available";
    } else if (
      selectedOption?.creditsValue?.label &&
      creditRemaining < 1 &&
      bookingType === "Appointment"
    ) {
      message = "You have insufficient credits to book this appointment";
    } else if (
      selectedOption?.creditsValue?.label &&
      creditRemaining < 1 &&
      bookingType === "Enrollment"
    ) {
      message = "You have insufficient credits to book this class";
    }

    return message;
  }
  return (
    <Box>
      <CustomLoader IsLoading={loader} />
      {!isCreditBuy ? (
        <>
          <Box className="appointment-main">
            <Box className="field-section">
              <Box
                className="appointment-dropdown"
                sx={{ width: "55% !important" }}
              >
                <InputLabel>Select Available Credits</InputLabel>
                <CustomDropdown
                  value={selectedOption.creditsValue}
                  placeHolder="Select Available Credits"
                  onChange={handleDropdownChange}
                  name={"creditsValue"}
                  options={creditOptions}
                  icon={downArrow}
                />
              </Box>
              <Box className="appointment-dropdown" />
            </Box>
          </Box>
          {creditUsed && creditRemaining > 0 && (
            <Box className="credit-remain" sx={{ mt: 2 }}>
              <Typography>Credits Used: {creditSelectedUsed}</Typography>
              <Typography>
                Remaining: <span> {creditRemaining}</span>
              </Typography>
            </Box>
          )}

          <Box className="credit-remain" sx={{ mt: 2, justifyContent: "center !important", alignItems: "center", gap: "0px !important" }}>
            <Typography>{errorMessege() ? `${errorMessege()}? ` : ''}</Typography>
            <pre> </pre>
            <Link className="red-link-line">
              {<Link
                className="link-line"
                onClick={() => handlePageNavigation()}
              >
                {errorMessege() && " Buy Credits"}
              </Link>}
            </Link>
          </Box>
          <CustomButton
            className="book-btn width-styling"
            title={"Redeem"}
            color="#fff"
            disabled={errorMessege() || !selectedOption?.creditsValue?.label}
            backgroundColor="#32B2AC"
            iconJsx={<ChevronRightIcon />}
            fullWidth
            onClick={handleAppointment}
          />
        </>
      ) : (
        <>
          <ItemDetail
            paramsFilter={paramsFilter}
            handleNext={setAddToCartPage}
            selected={selectedOption.creditsDetails}
            selectedType={bookingType}
            handleBack={handleBack}
            globalLocationId={locationId}
          />
        </>
      )}

      <Dialog
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography
                className="header-text-black"
                sx={{ textAlign: "justify !important" }}
              >
                Unfortunately, there are no room slot available at this
                time.Please explore another slot
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => setOpen(false)}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
