import { API_URL_3 } from "../../Utils/constants";
import { ApiServices } from "../ApiServices";
import { getLocalData } from "../../Utils";

const Base_URL = API_URL_3;

const locationId = encodeURIComponent(getLocalData("locationId"));

export const GetAllBuyCredits = async (payload) => {
  const encodedCategoryId = encodeURIComponent(payload.categoryId);
  const encodedServiceId = encodeURIComponent(payload.serviceId);
  try {
    return await ApiServices.get(
      // `${Base_URL}pricing-options?locationId=${encodeURIComponent(getLocalData('locationId'))}&categoryId=${encodedCategoryId}&services=${encodedServiceId}`
      `${Base_URL}pricing-options/getPricingOptionWithCategoryIdAndServiceId?serviceId=${encodedServiceId}&categoryId=${encodedCategoryId}&locationId=${encodeURIComponent(
        getLocalData("locationId")
      )}&status=1`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const GetCategory = async () => {
  try {
    return await ApiServices.get(
      `${Base_URL}services/getAllServiceCategories?locationId=${locationId}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const getAllService = async () => {
  try {
    return await ApiServices.get(
      `${Base_URL}services/getAllServices?locationId=${locationId}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const getCredits = async (data) => {
  try {
    return await ApiServices.post(
      // `${Base_URL}checkout/getAllCreditAvailability`,
      `${Base_URL}checkout/getAvailableCredits`,
      data
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const getPurchaseDetails = async (data) => {
  try {
    return await ApiServices.post(
      // `${Base_URL}checkout/getAllCreditAvailability`,
      `${Base_URL}schedule/_calculate-per-credit-price`,
      data
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
