import React, { useContext, useEffect, useState } from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import {
  CheckoutCreatePayment,
  CheckoutPlaceOrder,
  EmailPaymentReceipt,
  GetPaymentDetails,
  GetSavedCardList,
  RemoveAllCartItems,
} from "../../Services/APIs/shop";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { extractFirstName, extractLastName, generateEventId, generateItemData, getLocalData, isMetaLead, pushToDataLayer } from "../../Utils";
import { CustomLoader } from "../../Components/Common";
import BookingSuccess from "../Appointments/BookingSuccess";
import { ExploreContext, LocationDataContext } from "../../App";
import { ProfileModals } from "../../Components/Modals";
import { bookAppointment, getCredits, updateAppointment } from "../../Services/APIs";
import ReactPixel from 'react-facebook-pixel';
import { useNavigate } from "react-router-dom";
import { getCurrentTimeZone } from "../../Utils";
import { getEventData, getStoreDetails } from "../../Services/APIs/metaDataAnalytics";
import { getPurchaseDetails } from "../../Services/APIs/checkout";

const visaCard = require("../../assets/images/visaCard.svg").default;

function Checkout({ shopCheckoutTotal, handleNext, selectedPetCheckout, bookingType, selected, clientDetail, selectedOption, setSelectedOption, isWaitlist }) {
  const [isSaveCard, setIsSaveCard] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [savedCardList, setSavedCardList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [selectedCard, setSelectedCard] = useState({});
  const [openCardPopup, setOpenCardPopup] = useState(false);
  const [isPaymentFailed, setIsPaymentFailed] = useState(false);
  const [isPaymentPanding, setIsPaymentPanding] = useState(false);
  const [emailOrderId, setEmailOrderId] = useState();
  const { devCustomerId } = useContext(LocationDataContext);
  const { setIsOrderSuccess, checkoutZipCode } = useContext(ExploreContext);
  const cartItems = shopCheckoutTotal?.items.map((item) => {
    return {
      ...generateItemData(item?.itemType, item?.itemCategoryName, item?.itemId, item?.itemName),
      quantity: item?.quantity,
      price: item?.price
    }
  });
  console.log(' cartItems ~ shopCheckoutTotal->', shopCheckoutTotal);
  const contentIds = shopCheckoutTotal?.items.map((item) => item?.itemId)
  const locallyStoredUserData = getLocalData("userDataForDataLayer");
  const userData = locallyStoredUserData ? JSON.parse(locallyStoredUserData) : null;
  const dataLayerContent = {
    event: "trackOnlineConversion",
    event_name: "Purchase",
    event_time: Math.floor(new Date().getTime() / 1000),
    action_source: "online",
    event_id: generateEventId(),
    clientId: getLocalData('clientId'),
    transactionDetails: {
      tax: shopCheckoutTotal?.totalSquareTaxAmountInDollar || "",
      coupon: shopCheckoutTotal?.coupon || "",
      affiliation: getLocalData("franchiseeName"),
      items: cartItems,
      content_ids: contentIds,
    },
    user_data: userData,
  }
  const [dataLayer, setDataLayer] = useState(dataLayerContent);
  var dataLayerUpdatedTransactionDetailsData = {};
  console.log(' Checkout ~ dataLayer->', dataLayer);

  const navigate = useNavigate()
  let intervals = null;

  useEffect(() => {
    fetchEventDetails();
    fetchStoreDetails();
    if (devCustomerId) {
      handleGetSavedCardList(devCustomerId);
    }
  }, []);

  useEffect(() => {
    if (orderSuccess && emailOrderId) {
      handleEmailPaymentReceipt(emailOrderId);
    }
  }, [emailOrderId, orderSuccess]);

  const getPurchaseDetail = async (purchaseData) => {
    const payload = {
      orderId: purchaseData?.map((item) => item.orderId),
      purchaseId: purchaseData?.map((item) => item.sortKey),
      locationId: purchaseData[0].locationId,
      clientId: purchaseData[0].partitionKey,
    }
    const response = await getPurchaseDetails(payload);
    return response.data;
  }

  // sets the store details
  const fetchStoreDetails = async () => {
    try {
      const response = await getStoreDetails();
      if (response?.statusCode === 200) {
        const storeData = response?.data?.Item;
        setDataLayer((prev) => {
          const updatedStoreDetails = {
            store_id: storeData?.locationId,
            store_name: storeData?.locationName,
            store_location: `${storeData?.addressLine1} , ${storeData?.addressLine2}, ${storeData?.state} - ${storeData?.postalCode}`,
            store_city: storeData?.city,
          }
          return {
            ...prev,
            store_data: { ...prev?.store_data, ...updatedStoreDetails }
          }
        });
      }
    } catch (error) {
      console.error(error.message);
      return error;
    }
  };

  // sets the tracking meta ad details
  const fetchEventDetails = async () => {
    try {
      const response = await getEventData();
      if (response?.statusCode === 200) {
        const data = response?.data?.Items.length > 0 ? response?.data?.Items[0] : null;
        const { ad_store_id = '', ad_store_city, ad_store_location, ad_store_name, ...adData } = data?.trackingData;
        let dataLayerUpdatedStoreDetailsData = {};
        dataLayerUpdatedStoreDetailsData.ad_store_name = ad_store_name;
        dataLayerUpdatedStoreDetailsData.ad_store_location = ad_store_location;
        dataLayerUpdatedStoreDetailsData.ad_store_city = ad_store_city;
        dataLayerUpdatedStoreDetailsData.ad_store_id = ad_store_id;
        setDataLayer((prev) => {
          return {
            ...prev,
            store_data: { ...prev?.store_data, ...dataLayerUpdatedStoreDetailsData },
            trackingData: { ...prev?.trackingData, ...adData }
          }
        })
      }
    } catch (error) {
      console.error(error.message);
      return error;
    }
  };

  const handleEmailPaymentReceipt = (ID) => {
    let locationId = getLocalData("locationId");
    EmailPaymentReceipt(encodeURIComponent(ID), encodeURIComponent(locationId))
  }

  const handleSelectPaymentCard = (card) => {
    setSelectedCard(selectedCard?.id === card?.id ? {} : card);
    setOpenCardPopup(selectedCard?.id === card?.id ? false : true);
  };

  const activeCreds = (cardDetail) => {
    if (selectedCard?.id) {
      return {
        brand: selectedCard?.cardBrand,
        last4: selectedCard?.last4,
        expMonth: selectedCard?.expMonth,
        expYear: selectedCard?.expYear,
        cardholderName:
          selectedCard?.cardholderName ||
          JSON.parse(getLocalData("user_detail"))?.name,
        isCardSaved: false,
        postalCode: checkoutZipCode || ''
      };
    } else {
      return {
        brand: cardDetail?.details?.card?.brand,
        last4: cardDetail?.details?.card?.last4,
        expMonth: cardDetail?.details?.card?.expMonth,
        expYear: cardDetail?.details?.card?.expYear,
        cardholderName:
          cardDetail?.details?.card?.cardholderName ||
          JSON.parse(getLocalData("user_detail"))?.name,
        isCardSaved: isSaveCard,
        postalCode: checkoutZipCode || ''
      };
    }
  };

  const handleCreatePayment = async (placedOrderDetail, cardDetail) => {
    setLoader(true);
    const reqObject = {
      amount:
        placedOrderDetail?.sqaureOrderData?.netAmounts?.totalMoney?.amount,
      card: activeCreds(cardDetail),
      clientId: placedOrderDetail?.clientId,
      franchiseeId: placedOrderDetail?.franchiseeId,
      locationId: placedOrderDetail?.locationId,
      orderId: placedOrderDetail?.ZROrderId,
      paymentMode: "CARD",
      source:
        selectedCard && selectedCard?.id ? selectedCard?.id : cardDetail?.token,
      squareOrderId: placedOrderDetail?.sqaureOrderId,
    };

    if (shopCheckoutTotal?.totalSquareAmountWithTaxInDollar === 0) {
      delete reqObject.card;
      reqObject.amount = 0;
      reqObject.paymentMode = "CASH";
      reqObject.source = "CASH";
      reqObject.cashDetails = {
        buyerSuppliedMoney: {
          amount: 0,
          currency: "USD",
        },
        changeBackMoney: {
          amount: 0,
          currency: "USD",
        },
      };
    }

    const response = await CheckoutCreatePayment(reqObject);
    if (response?.statusCode === 200) {
      intervals = setInterval(handleGetPaymentDetails(response?.data), 5000);
      const txctnData = response?.data?.payment;

      dataLayerUpdatedTransactionDetailsData.transaction_id = txctnData?.id;
      dataLayerUpdatedTransactionDetailsData.value = txctnData?.amountMoney?.amount;
      dataLayerUpdatedTransactionDetailsData.currency = txctnData?.amountMoney?.currency;
      setDataLayer((prev) => {
        const updatedTransactionDetails = { ...dataLayerUpdatedTransactionDetailsData, ...prev?.transactionDetails };
        return {
          ...prev,
          transactionDetails: updatedTransactionDetails
        }
      });
      // Code for purchase event of meta pixel
      ReactPixel.track('Purchase', {
        value: placedOrderDetail?.totalSquareAmountWithTaxInDollar,
        currency: 'USD',
      });

    } else {
      setLoader(false);
      setIsPaymentFailed(true);
    }
  };
  const handleGetSavedCardList = async (customerId) => {
    setLoader(true);
    const response = await GetSavedCardList(customerId);
    if (response?.statusCode === 200) {
      setSavedCardList(response?.data);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked((prevChecked) => !prevChecked);
  };

  useEffect(() => {
    if (shopCheckoutTotal?.totalSquareAmountWithTaxInDollar === 0) {
      handleCheckoutPlaceOrder();
    }
  }, [shopCheckoutTotal]);

  const handleCheckoutPlaceOrder = async (cardDetail) => {
    setLoader(true);
    const reqObj = {
      franchiseeId: getLocalData("franchiseeId"),
      locationId: getLocalData("locationId"),
      franchiseeName: getLocalData("franchiseeName"),
      locationName: getLocalData("locationName"),
      createdByName: JSON.parse(getLocalData("user_detail")).name,
      clientId: getLocalData("clientId"),
      clientName: JSON.parse(getLocalData("user_detail")).name,
      guestClient: false,
      createdBy: getLocalData("createdBy"),
      queueId: getLocalData("queueId"),
      petId: selectedPetCheckout?.dog?.value?.sortKey,
      petName:
        selectedPetCheckout?.dog?.value?.firstName +
        " " +
        selectedPetCheckout?.dog?.value?.lastName,
    };

    const contractId = getLocalData("contractId")
    const signatureImage = getLocalData("signatureImage")

    if (contractId && signatureImage) {
      reqObj.contractId = contractId
      reqObj.signatureImage = signatureImage
    }

    const response = await CheckoutPlaceOrder(reqObj);
    if (response?.statusCode === 200) {
      handleCreatePayment(response?.data, cardDetail);
      setEmailOrderId(response?.data?.ZROrderId)
    } else {
      setLoader(false);
    }
  };

  const handlePaymentFailed = () => {
    clearInterval(intervals);
    setIsPaymentPanding(true);
  };

  const handleGetPaymentDetails = async (data) => {
    setLoader(true);
    let locationId = getLocalData("locationId");
    const reqData = {
      locationId: encodeURIComponent(locationId),
      sqaureOrderId: encodeURIComponent(data?.payment?.orderId),
    };
    const response = await GetPaymentDetails(reqData);
    if (response?.statusCode === 200) {
      // push datalayer here
      if (isMetaLead(dataLayer)) {
        const updatedTransactionDetails = { ...dataLayerUpdatedTransactionDetailsData, ...dataLayer?.transactionDetails };
        console.log('Purchase ~ dataLayer->', { ...dataLayer, transactionDetails: updatedTransactionDetails });
        pushToDataLayer({ ...dataLayer, transactionDetails: updatedTransactionDetails });
      }
      clearInterval(intervals);
      handleRemoveAllCartItems();
      // }
    } else {
      setTimeout(() => handlePaymentFailed(), 30000);
    }
  };

  const handleRemoveAllCartItems = async () => {
    setLoader(true);
    let userLocationId = getLocalData("locationId");
    let userClientId = getLocalData("cartID");

    const response = await RemoveAllCartItems(userLocationId, userClientId);
    if (response?.statusCode === 200) {
      if (bookingType !== "Enrollment" && bookingType !== 'Appointment') {
        setOrderSuccess(true);
        setIsOrderSuccess(true)
        setLoader(false)
      } else {
        setTimeout(() => {
          handleGetAvailableCredits()
        }, 5000)
      }
      // setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const handleGetAvailableCredits = () => {
    setLoader(true);
    let { fromDate, toDate } = selected?.[0]
    let locationId = getLocalData("locationId");
    const repeat = 'noRepeat'
    fromDate = fromDate.replaceAll('/', '-')
    toDate = toDate.replaceAll('/', '-')
    let serviceId = (selected?.length > 0 && selected?.[0]?.serviceId) || selectedOption?.serviceName?.value?.sortKey;
    let categoryId = (selected?.length > 0 && selected?.[0]?.categoryId) || selectedOption?.categoryName?.value?.sortKey;
    let clientId = clientDetail?.sortKey;
    let petId = selectedOption?.dog?.value?.sortKey;
    let requestObj = {
      serviceId, categoryId, clientId, locationId, petId, repeat, fromDate, toDate
    }

    getCredits(requestObj).then(
      (response) => {
        if (response) {
          setLoader(false);
          let credits = response?.data?.isSufficiantCredits;
          if (credits) {
            let validCredits = response?.data?.Items?.filter((item) => item?.remainingSession > 0)
            let purchaseId = validCredits[0]?.sortKey
            if (bookingType === "Enrollment") {
              handleBookSchedule(purchaseId, response?.data?.Items)
            } else {
              setAppointment(purchaseId, response?.data?.Items)
            }
          }
        }
      }
    );
  }
  const setAppointment = (purchaseId, purchaseData) => {
    setLoader(true);
    const userData = getLocalData("user_detail");
    let locationId = getLocalData("locationId");
    let franchiseeId = getLocalData("franchiseeId");
    let clientEmail = JSON.parse(userData);
    setLoader(true);
    let room = {
      id: selectedOption?.room?.roomId,
      name: selectedOption?.room?.roomName,
      roomLimit: 20,
    };
    let paretlastName = clientDetail?.lastName;
    let childLastName = selectedOption.dog.value.lastName
    const payLoad = {
      scheduleType: "appointment",
      clientDetails: [
        {
          payLater: false,
          creditAvailable: 1,
          checkIn: "00:00",
          clientParentId: clientDetail?.sortKey,
          clientChildId: selectedOption?.dog?.value?.sortKey,
          clientParentName: clientDetail?.firstName,
          // clientParentLastName: clientDetail?.lastName || " ",
          clientChildName: selectedOption?.dog?.value?.firstName,
          // clientChildLastName: selectedOption.dog.value.lastName || " ",
          clientChildProfile: selectedOption?.dog?.value?.profileImage,
          clientChildBirthDate: selectedOption?.dog?.value?.birthDate,
          clientEmailId: clientEmail?.email,
          bookedBy: clientDetail.firstName,
          purchaseId: [purchaseId],
        },
      ],
      categoryName: selectedOption.categoryName.value.name,
      categoryId: selectedOption.categoryName.value.categoryId,
      serviceName: selectedOption.serviceName.value.name,
      serviceId: selectedOption?.serviceName?.value?.sortKey,
      repeat: "noRepeat",
      occurrence: {
        freq: "noRepeat",
        dtstart: `${selectedOption.fromDate}, ${selectedOption.startTime}`,
        until: `${selectedOption.fromDate}, ${selectedOption.endTime}`,
      },
      room: room,
      description: selectedOption.notes,
      createdBy: clientDetail.firstName,
      trainerId: selectedOption.trainerId,
      trainerName: extractFirstName(selectedOption?.trainerName),
      trainerLastName: extractLastName(selectedOption?.trainerName) || " ",
      trainerProfileImage: selectedOption.trainerProfileImage,
      trainerAvailability: selectedOption.trainerAvailability,
      locationId: locationId,
      franchiseeId: franchiseeId,
      timeZone: getCurrentTimeZone()
    };
    if (paretlastName) {
      payLoad.clientDetails[0].clientParentLastName = paretlastName
    }
    if (childLastName) {
      payLoad.clientDetails[0].clientChildLastName = childLastName
    }
    bookAppointment(payLoad).then(async (response) => {
      if (response.statusCode === 200) {
        console.log(' bookAppointment ~ response->', response, dataLayer);
        // push datalayer here
        if (isMetaLead(dataLayer)) {
          const appointmentId = response?.data?.AppointmentId;
          const details = await getPurchaseDetail(purchaseData);
          const { itemName, itemCategoryName, itemType } = purchaseData[0]?.purchaseItems;
          const updatedTransactionDetails = { ...dataLayerUpdatedTransactionDetailsData, ...dataLayer?.transactionDetails, items: [{ ...generateItemData(itemType, itemCategoryName, appointmentId, itemName, "booking"), price: details[0]?.pricePerSession, quantity: 1 }], value: details[0]?.pricePerSession, content_ids: [details[0]?.itemId] };
          console.log('Appointment ~ dataLayer-> appointment', { ...dataLayer, transactionDetails: updatedTransactionDetails });
          pushToDataLayer({ ...dataLayer, transactionDetails: updatedTransactionDetails });
        }
        setLoader(false);
        setOrderSuccess(true);
        setIsOrderSuccess(true)
        navigate('/appointment')
      } else {
        setLoader(false);
      }
    });
  };

  const handleBookSchedule = async (purchaseId, purchaseData) => {
    setLoader(true);
    const appointmentId = selected?.[0].sortKey;
    const locationId = getLocalData("locationId")
    const payload = {
      clientDetails: {
        clientParentId: clientDetail.sortKey,
        serviceId: selected?.[0]?.serviceId,
        serviceName: selected?.[0]?.serviceName,
        categoryId: selected?.[0]?.categoryId,
        categoryName: selected?.[0]?.categoryName,
        clientParentName: clientDetail?.firstName,
        clientParentLastName: clientDetail?.lastName || " ",
        clientChildId: selectedOption?.dog?.value?.sortKey,
        clientChildName: selectedOption.dog.value.firstName,
        clientChildLastName: selectedOption.dog.value.lastName || " ",
        bookedBy: clientDetail.firstName,
        clientChildProfile: selectedOption.dog.value.profileImage,
        clientChildBirthDate: selectedOption?.dog?.value?.birthDate,
        payLater: false,
        creditAvailable: 2,
        clientEmailId: clientDetail.email,
        waitingList: isWaitlist,
        purchaseId: [purchaseId],
      },
      fromDate: selected?.[0].fromDate,
      locationId: locationId,
      updatedBy: selected?.[0].createdBy,
      type: "occurrence",
    };
    updateAppointment(payload, appointmentId).then(async (response) => {
      if (response.statusCode === 200) {
        console.log(' Enrollment ~ response->', response);
        // push datalayer here
        if (isMetaLead(dataLayer)) {
          const appointmentId = response?.data["Updated Details"]?.appointmentId;
          const details = await getPurchaseDetail(purchaseData);
          const { itemName, itemCategoryName, itemType } = purchaseData[0]?.purchaseItems;
          const updatedTransactionDetails = { ...dataLayerUpdatedTransactionDetailsData, ...dataLayer?.transactionDetails, items: [{ ...generateItemData(itemType, itemCategoryName, appointmentId, itemName, "booking"), price: details[0]?.pricePerSession, quantity: 1 }], value: details[0]?.pricePerSession, content_ids: [details[0]?.itemId] };
          console.log('Enrollment ~ dataLayer-> Enrollment', { ...dataLayer, transactionDetails: updatedTransactionDetails });
          pushToDataLayer({ ...dataLayer, transactionDetails: updatedTransactionDetails });
        }
        setLoader(false);
        setOrderSuccess(true);
        setIsOrderSuccess(true)
      } else {
        setLoader(false);
      }
    });
  }

  return (
    <>
      <CustomLoader IsLoading={loader} />
      {orderSuccess ? (
        <>
          <BookingSuccess bookingType={''} handleBack={handleNext} orderPlaced={!bookingType} />
        </>
      ) : (
        shopCheckoutTotal?.totalSquareAmountWithTaxInDollar > 0 && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box className="checkout_shop_form">
              <Typography className="payment_total">
                ${parseFloat(shopCheckoutTotal?.totalSquareAmountWithTaxInDollar || 0)?.toFixed(2)}
              </Typography>
              <Box
                className="checkout_shop_form_inner"
                p={{ xs: "17px", sm: "30px" }}
              >
                <PaymentForm
                  environment="sandbox"
                  applicationId={process.env.REACT_APP_SQUARE_PAY_APP_ID}
                  locationId={shopCheckoutTotal?.squareLocationId || ""}
                  cardTokenizeResponseReceived={async (
                    token,
                    verifiedBuyer
                  ) => {
                    handleCheckoutPlaceOrder(token);
                  }}
                  createPaymentRequest={() => ({
                    countryCode: "US",
                    currencyCode: "USD",
                    total: {
                      amount: shopCheckoutTotal?.order?.totalMoney?.amount,
                      label: "Total",
                    },
                  })}
                  formProps={{
                    className: "payment-form-cls",
                  }}
                >
                  <div>
                    <CreditCard
                      render={(Button) => (
                        <div>
                          {/* Checkbox for saving card */}
                          <Box sx={{ mb: 1 }}>
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                setIsSaveCard(e.target.checked);
                              }}
                            />
                            {"   "} Save card for future use
                          </Box>
                          {/* Proceed button */}
                          <Button
                            style={{
                              padding: "11px 23px",
                              borderRadius: "10px",
                              textTransform: "capitalize",
                              width: "100%",
                              fontWeight: "600",
                              fontSize: "16px",
                              margin: "0 auto 24px",
                              backgroundColor: "#32B2AC",
                            }}
                          >
                            Pay Now
                          </Button>
                        </div>
                      )}
                    />
                  </div>
                </PaymentForm>
                {savedCardList && savedCardList?.length ? (
                  <Typography className="heading">Saved Card</Typography>
                ) : (
                  ""
                )}
                {savedCardList && savedCardList?.length
                  ? savedCardList?.map((card, index) => (
                    <Box
                      className="slots-wrap card-list-detail-wrap"
                      key={index}
                      onClick={() => {
                        handleSelectPaymentCard(card);
                      }}
                    >
                      <Box className="">
                        <img src={visaCard} alt="visa card" />
                      </Box>
                      <Box className="card-list-detail">
                        <Box className="card-list-section">
                          <Box>
                            <Typography className="heading">
                              {card?.cardBrand} ending in {card?.last4}
                            </Typography>
                            <Typography className="sub-heading">
                              Expiry {card?.expMonth}/{card?.expYear}
                            </Typography>
                          </Box>
                          {/* dont remove this <Box className="color-btn-wrap">
                                <Link className="gray-btn">Set as default</Link>
                                <Link className="blue-btn">Edit</Link>
                                <Link className="red-btn">Remove</Link>
                              </Box> */}
                        </Box>
                        <Checkbox
                          checked={selectedCard?.id === card?.id}
                          indeterminate={selectedCard?.id === card?.id}
                          indeterminateIcon={<CheckCircleIcon />}
                          icon={<RadioButtonUncheckedIcon />}
                          onChange={handleCheckboxChange}
                        />
                      </Box>
                    </Box>
                  ))
                  : null}
              </Box>
            </Box>
          </Box>
        )
      )}

      <ProfileModals
        open={openCardPopup}
        type={"selectPaymentCard"}
        handleActionBtn={() => setOpenCardPopup(false)}
        handleClose={() => {
          setSelectedCard({})
          setOpenCardPopup(false)
        }}
        handlePaymentWithExistingCard={() => {
          handleCheckoutPlaceOrder(selectedCard);
          setOpenCardPopup(false);
        }}
      />

      <ProfileModals
        open={isPaymentFailed}
        type={"paymentFailed"}
        handleActionBtn={() => setIsPaymentFailed(false)}
      />
      <ProfileModals
        open={isPaymentPanding}
        type={"paymentPanding"}
        handleActionBtn={() => setIsPaymentPanding(false)}
      />
    </>
  );
}

export default Checkout;
